/**
 * The class is used to simplify environment variable injection for generated
 * application in Docker container
 *
 * More info can be found on StackOverflow
 * https://stackoverflow.com/questions/57807248/vuecli3-app-nginx-docker-use-environment-specific-variables
 * and in SARDANA-19
 */
export default class Configuration {
  /**
   * the property, containing the map of keys to environment variables
   */
  static get EnvConfig(): Record<string, string> {
    return {
      /**
       * the base URL of the backend REST endpoints
       */
      apiBaseUrl: "https://rest.eval.coremailservice.net/sardana/",
      /**
       * base application URL, used by `VueRouter`
       */
      baseUrl: "/",
      /**
       * the `VueRouter` mode (one of 'hash', 'history', 'abstract')
       */
      routerMode: "hash",
    }
  }

  /**
   * get the configuration parameter value for specified key
   *
   * @param key     one of the keys, contained in {@code EnvConfig}
   */
  static value(key: string): string | undefined {
    let envValue

    // If the key does not exist in the EnvConfig object of the class, return null
    const envConfig = this.EnvConfig
    if (!Object.prototype.hasOwnProperty.call(envConfig, key)) {
      console.error(
        `Configuration: There is no key named "${key}". Please add it in Configuration class.`
      )
    } else {
      // Get the value
      const value = envConfig[key]

      // If the value is null, return
      if (!value) {
        console.error(`Configuration: Value for "${key}" is not defined`)
      } else {
        if (!value.startsWith("$VUE_APP_")) {
          // value was already replaced, it seems we are in production (containerized).
          envValue = value
        } else {
          // value was not replaced, it seems we are in development.
          const envName = value.substr(1) // Remove $ and get current value from process.env
          envValue = process.env[envName]

          if (!envValue) {
            console.error(
              `Configuration: Environment variable "${envName}" is not defined`
            )
          }
        }
      }
    }

    return envValue
  }
}
